<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div>
                    <h5 class="titulo_pedidos">Integrações</h5>
                </div>
                <Loading v-if="loading" />
                <div v-if="!loading">
                    <div v-for="(marketplace, index) in marketplaces" :key="index">
                        <a @click="modalCadastrarIntegracao(marketplace.id,marketplace.nome_hub)">
                            <div class="marketplace">
                                <h4>{{marketplace.nome}}</h4>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog header="Adicionar Integração" v-model:visible="mostrarAdicionar" style="width:500px" >
        {{ this.hubSelecionado }}
        <div class="flex flex-column">
            <div class="grid">
                <div class="col-12">
                    <label>Nome</label>
                    <InputText  style="width:100%" v-model="this.integracao_nome"/>
                </div>
            </div>
            <div class="grid">
                <div class="col-12">
                    <label>Usuário</label>
                    <InputText  style="width:100%" v-model="this.integracao_usuario"/>
                </div>
            </div>
            <div v-if="this.hubSelecionado != 'ML'">
                <div class="grid">
                    <div class="col-12">
                        <label>Access Token</label>
                        <InputText  style="width:100%" v-model="this.integracao_access_token"/>
                    </div>    
                </div>
                <div class="grid">
                    <div class="col-12">
                        <label>CNPJ CD</label>
                        <InputText  style="width:100%" v-model="this.integracao_cnpj"/>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="grid">
                    <div class="col-12">
                        <label>Client ID</label>
                        <InputText  style="width:100%" v-model="this.client_id_mercado_livre"/>
                    </div>
                </div>
            </div>
                
            <div class="btns">
                <Button label="Adicionar" class="p-button-success" @click="cadastrarIntegracao()"/> 
                <Button label="Cancelar" class="p-button-danger" @click="cancelarIntegracao()"/> 
            </div>
        </div>
    </Dialog>
</template>

<script>
import Api from '../service/api';
import Loading from '../components/Loading.vue';
export default {
    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            empresa_id:0,
            api: null,
            loading: true,
            mostrarAdicionar:false,
            marketplaces:[],
            origemSelecao: null,
            displayAnuncio: false,
            origem: [
                { nome: "0 - Nacional, exceto as indicadas nos códigos 3 a 5" }
            ],
            tipoSelecao: null,
            tipo: [
                "Interno",
                "Simples"
            ],
            tipoEmbalagem: null,
            embalagem: [
                { nome: "Pacote/Caixa" }
            ],
            embalagemCustomizada: null,
            customizada: [
                { nome: "Embalagem Customizada" }
            ],
            valorSimNao: null,
            simNao: [
                { nome: "Sim" },
                { nome: "Não" }
            ],
            integracao_nome:"",
            integracao_usuario:"",
            integracao_access_token:"",
            integracao_cnpj:""
        };
    },
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.api = new Api();
    },
    mounted() {
        this.listarMarketplaces();
    },
    methods: {
        listarMarketplaces() {
        //    this.loading = true;
            this.api.listarMarketplaces(this.token).then(data => {
                this.marketplaces = data.data.resposta
              this.loading = false;
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        modalCadastrarIntegracao(id,hub){
            this.marketplaceSelecionado = id;
            this.mostrarAdicionar = true;
            this.hubSelecionado = hub;
        },
        cadastrarIntegracao() {
            if(this.nome !== "")
            this.api.criarMarketplaceEmpresa(this.token,this.empresa_id, this.marketplaceSelecionado, this.integracao_nome, this.integracao_usuario, this.integracao_access_token, this.integracao_cnpj,this.client_id_mercado_livre)
            this.integracao_nome = ""
            this.integracao_usuario = ""
            this.integracao_access_token = ""
            this.integracao_cnpj = ""
            this.$router.push('/integracoes');
            
        },
        cancelarIntegracao(){
            this.integracao_nome = ""
            this.integracao_usuario = ""
            this.integracao_access_token = ""
            this.integracao_cnpj = ""  
            this.mostrarAdicionar = false;

        }
    },
    components: { Loading }
}
</script>
<style scoped>

.p-button-success{
    background-color: #4e87ee !important;
    border: none;
    margin-right: 10px;
}
.p-button-danger {
    background-color: #9d9d9d !important;
    border: none;
}
.inputContainer {
    display: flex;
    flex-direction: column;
}

.btns{
    text-align: center;
}

.inputGroup {
    margin-top: 25px;
}

.inputGroup div {
    margin: 5px;
}

hr {
    border: 1px solid #00000040;
}


.titulo_pedidos{
    display: inline-block; 
    width: 50%;
}
.botoes_topo{
    display: inline-block;
    width: 50%;
    text-align:right
}
</style>